// Third party imports
import React, { useCallback } from "react";

// Util functions and providers
import { is_empty } from "../../../../../../utils/validations";
import { sendEmail } from "../../../../../Fields/EmailField";
import AddNoteIcon from "assets/vectors/crm/add_note_icon.svg";
import AddTagIcon from "assets/vectors/crm/add_tags_icon.svg";
// Styles
import useStyles from "./TableColumns.styles";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import ExlyImage from "common/Components/ExlyImage";

export const NameField = ({ record = {}, source, onClick }) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      if (onClick) onClick(record);
    },
    [record]
  );

  if (is_empty(record[source])) return "N/A";

  return (
    <span onClick={handleClick} className={classes.name_field}>
      {record[source]}
    </span>
  );
};

export const TagsField = ({ record, onClick }) => {
  const { tags } = record;

  //Sowing first 2 tags only
  const tagsToShow = tags?.slice(0, 2);
  const classes = useStyles();
  // finding out how many more tags are there
  const moreVal = tags?.length - 2;
  return (
    <>
      {tagsToShow?.length > 0 ? (
        <div className={classes.tagsWrapper} onClick={onClick}>
          {tagsToShow.map((tag) => (
            <div key={tag} className={classes.tag}>
              {" "}
              {tag.name}
            </div>
          ))}
          {moreVal > 0 && <div className={classes.more}>+{moreVal} more</div>}
        </div>
      ) : (
        <div>N/A</div>
      )}
    </>
  );
};

export const EmailField = ({ record = {}, source }) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles({ isDesktop });

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      sendEmail(record[source]);
    },
    [record, source]
  );

  if (is_empty(record[source])) return "N/A";

  return (
    <span onClick={onClick} className={classes.email_field}>
      {record[source]}
    </span>
  );
};

export const ActionField = ({ record = {}, onClick }) => {
  const classes = useStyles();

  const handleClick = (event) => {
    event.preventDefault();
    onClick(record);
  };

  return (
    <div onClick={handleClick} className={classes.desktop_action_field}>
      View activity
    </div>
  );
};

export const CustomerActionsField = ({ record = {}, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.info_field_wrapper}>
      <div id="note">
        <ExlyImage
          src={AddNoteIcon}
          alt="Add Note"
          height={20}
          width={20}
          id="note"
          onClick={(e) => onClick(record, e)}
        />
      </div>
      <div id="tag">
        <ExlyImage
          src={AddTagIcon}
          alt="Add Note"
          height={20}
          width={20}
          id="tag"
          onClick={(e) => onClick(record, e)}
        />
      </div>
    </div>
  );
};
